
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import DefinePasswordControl from "@/components/controls/DefinePasswordControl.vue";
import TextControl from "@/components/controls/base/TextControl.vue";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    DefinePasswordControl,
    TextControl,
    CatalogueSelectControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },

    onShown(model: any) {
      (this as any).mountComponent(
        "components/catalogues/UserCatalogue/UserRoleSubCatalogueGrid.vue",
        (this as any).roleSubGridDomId,
        {
          parentModel: model,
        }
      );
    },
  },
  beforeUnmount() {
    (this as any).unmountComponent((this as any).roleSubGridDomId);
  },
  data() {
    return {
      roleSubGridDomId: "UserCreateEditDialog_roleSubCatalogueGrid",
    };
  },
});
